@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    outline: none !important;
  }
}
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #fefcf7;
}

a {
  text-decoration: underline;
}

/* start header */
.header a {
  text-decoration: none;
}

.Dropdown-control {
  border: none !important;
  color: #359aff !important;
  background-color: transparent !important;
}

.Dropdown-menu {
  border: none !important;
}

.Dropdown-option {
  color: #cccccc;
  font-weight: 500;
  font-size: 16px;
  padding-top: 2px !important;
  padding: 0px 5px;
}

.Dropdown-control:hover {
  box-shadow: none !important;
}

/*start form css */
.radio-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #359aff;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.radio-container:hover input ~ .checkmark {
  background-color: #359aff;
}

.radio-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #faf4eb;
}

input:focus-visible {
  outline: none;
}

textarea:focus-visible {
  outline: none;
}

textarea {
  resize: none;
}

.arrow-icons {
  stroke-width: 3px;
}

/*end form css */

@media (max-width: 767px) {
  .logo {
    width: 80px;
    height: 30px;
  }

  .slideImage {
    height: 500px;
  }

  .app-icon {
    width: 130px;
  }

  .Dropdown-option {
    padding: 3px 5px;
  }

  .signImage {
    width: 40.4656mm;
  }
  .signImageV2 {
    width: 27.151mm;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .app-icon {
    width: 130px;
  }
  .signImage {
    width: 40.4656mm;
  }
  .signImageV2 {
    width: 27.151mm;
  }
}

@media (min-width: 1025px) and (max-width: 1536px) {
  .home-content {
    height: calc(100% - 80px);
  }

  .app-icon {
    width: 128px;
  }
}
@media (max-width: 375px) {
  .text-11px {
    font-size: 12px;
  }
}
.header-scroll {
  background-color: #359aff;
  color: black;
  font-weight: 600;
  color: #fffcf7;
}
.header-scroll .scrollLink {
  font-weight: 600;
}
.header-scroll .logo_color {
  fill: #fffcf7;
}
.header-scroll .fill-color {
  fill: #fffcf7;
}
