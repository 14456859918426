@import url("./variable.css");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Rajdhani:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: dmmono-font;
  src: url(../dmmono-font/DMMono-Regular.ttf);
}
@font-face {
  font-family: space-grotesk;
  src: url(../space-grotest-font/SpaceGrotesk-Regular.ttf);
}
